/* eslint-disable prettier/prettier */
export default {
  'home': {
    'title': 'Documentation | Inperium',
    'description': 'Inperium online documentation portal has all the information you need to get started within minutes and use Inperium products to their fullest.'
  },
  'account': {
    'title': 'Overview | Inperium Account Docs',
    'description': 'Get started with Inperium — activate, manage, and configure your Inperium account.'
  },
  'account-tenant-and-billing': {
    'title': 'Tenant & Billing | Inperium Account Docs',
    'description': 'Learn more about tenant management and billing options.'
  },
  'account-profile-and-privacy': {
    'title': 'Profile & Privacy | Inperium Account Docs',
    'description': 'Configure your personal profile and secure access to your account.'
  },
  'security': {
    'title': 'Security | Inperium Docs',
    'description': 'With attention to data security and integrity, Inperium protects customer data and stays GDPR compliant.'
  },
  'sell': {
    'title': 'Getting Started | Inperium Sell Docs',
    'description': 'Get started with Inperium Sell and turboboost your sales.'
  },
  'sell-companies-and-contacts': {
    'title': ' Companies & Contacts | Inperium Sell Docs',
    'description': 'Create and manage companies and contacts in Inperium Sell.'
  },
  'sell-deals-and-quotes': {
    'title': 'Deals & Quotes | Inperium Sell Docs',
    'description': 'Create deals and process quotes in Inperium Sell.'
  },
  'sell-payment-center': {
    'title': 'Payment Center | Inperium Sell Docs',
    'description': 'Create invoices and collect payments in Inperium Sell.'
  },
  'sell-dashboard-and-reporting': {
    'title': 'Dashboard & Reporting | Inperium Sell Docs',
    'description': 'Improve your productivity with an interactive dashboard and reports.'
  },
  'sell-message-center-and-activities': {
    'title': 'Message Center & Activities | Inperium Sell Docs',
    'description': 'Set up the Message Center to send and receive emails right in Inperium Sell and keep tabs on activity timeline.'
  },
  'sell-sell-settings': {
    'title': 'Sell Settings | Inperium Sell Docs',
    'description': 'Customize and fine-tune Inperium Sell.'
  },
  'talk': {
    'title': 'Getting Started | Inperium Talk Docs',
    'description': 'Get started with Inperium Talk, the cloud PBX phone system.'
  },
  'talk-contacts-and-calls': {
    'title': 'Contacts & Calls | Inperium Talk Docs',
    'description': 'Add your clients and peers to Contacts and make calls with Inperium Talk.'
  },
  'talk-auto-receptionist': {
    'title': 'Auto-Receptionist | Inperium Talk Docs',
    'description': 'Provide 24/7 services and accept calls even during non-working hours.'
  },
  'talk-dashboard-and-reporting': {
    'title': 'Dashboard & Reporting | Inperium Talk Docs',
    'description': 'Improve your productivity with an interactive dashboard and reports.'
  },
  'talk-call-queues': {
    'title': 'Call Queues | Inperium Talk Docs',
    'description': 'Organize calls in queues to provide better customer experience.'
  },
  'talk-audio-library-and-settings': {
    'title': 'Audio Library & Settings | Inperium Talk Docs',
    'description': 'Configure and fine-tune Inperium Talk.'
  },
  'faq': {
    'title': 'Frequently Asked Questions | Inperium Docs',
    'description': 'Answers to the most commonly asked questions.'
  },
  'faq-subscription-and-billing': {
    'title': 'Subscription & Billing | FAQ | Inperium Docs',
    'description': 'Get up-to-date information on Inperium billing and subscriptions.'
  },
  'faq-issues-and-troubleshooting': {
    'title': 'Issues & Troubleshooting | FAQ | Inperium Docs',
    'description': 'Workarounds and quick fixes to the most common issues.'
  }
}