import cn from 'classnames';
import {Link as GatsbyLink} from 'gatsby';
import {FC} from 'react';
import {LinkProps} from 'react-router-dom';

import {isUrlAbsolute, isUrlMailTo, isUrlPhoneNumber} from '@inperium-corp/core';
import {useLanguageLink} from '../../hooks/useLanguageLink';

interface ILanguageLinkProps extends LinkProps {
  /**
   * Set up the color that will be used for all descedant texts and icons.
   * Set up the hover color as well.
   */
  colorScheme?: 'blue' | 'blue-800' | 'blue-870';

  /**
   * If set to true, then all inner text components remains their paddings.
   * Otherwise, all inner text paddings set to 0.
   * @default false
   */
  textPaddings?: boolean;
}

export const LanguageLink: FC<ILanguageLinkProps> = ({
  to,
  colorScheme,
  className,
  textPaddings = false,
  children,
  ...props
}) => {
  const isAbsolute =
    (to && (isUrlAbsolute(to as string) || isUrlMailTo(to as string))) || isUrlPhoneNumber(to as string);
  const finalLink = useLanguageLink(to as string);

  const mergedClassName = cn(
    className,
    `language-link`,
    colorScheme && `language-link__color-${colorScheme}`,
    !textPaddings && 'language-link__remove-text-paddings'
  );
  return isAbsolute ? (
    <a href={to.toString()} className={mergedClassName} {...props}>
      {children}
    </a>
  ) : (
    <GatsbyLink to={finalLink} className={mergedClassName} {...props}>
      {children}
    </GatsbyLink>
  );
};
