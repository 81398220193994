/**
 * General app settings.
 */
export const appSettings = {
  marketingSiteUrl: process.env.GATSBY_MARKETING_SITE_URL || 'https://inperium.com',
  documentationSiteUrl: process.env.GATSBY_DOCUMENTATION_URL || 'https://docs.inperium.com',
  apiDocumentationSiteUrl: process.env.GATSBY_API_DOCUMENTATION_SITE_URL || 'https://developers.inperium.com',
  supportSiteUrl: process.env.GATSBY_SUPPORT_SITE_URL || 'https://support.inperium.com',
  accountUrl: process.env.GATSBY_ACCOUNT_URL || 'https://account.inperium.com',
  sellProductUrl: process.env.GATSBY_SELL_PRODUCT_URL || 'https://sell.inperium.com',
  talkProductUrl: process.env.GATSBY_TALK_PRODUCT_URL || 'https://talk.inperium.com',
  cookieDomain: process.env.GATSBY_COOKIE_DOMAIN || 'inperium.com',
  signInUrl: process.env.GATSBY_ACCOUNT_URL || 'http://account.inperium.com',
  signUpUrl: process.env.GATSBY_SIGN_UP_URL || 'http://account.inperium.com/sign-up',
  logoutUrl: process.env.GATSBY_LOGOUT_URL || 'http://account.inperium.com/logout',
  apiUrl: process.env.GATSBY_API_HUB_URL || 'https://api.inperium.com/v1/hub'
};
