import {escapeSpecialChars} from './escapeSpecialChars';

/**
 * This function makes all words from incoming query required for lunr search.
 * @param query lunr search query
 * @returns string
 */
export const makeAllWordsRequired = (query: string) => {
  return query
    .split(' ')
    .filter((word) => word.length > 0)
    .map((word) => `+${escapeSpecialChars(word)}*`)
    .join(' ');
};
