import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {en} from './locales/en';
import {de} from './locales/de';

const instance = i18n.createInstance();

instance
  // pass the i18n instance to the react-i18next components.
  // Alternatively use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en,
      de,
    },
    lng: 'en',
    debug: true,
    defaultNS: 'common',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
    },

    returnObjects: true,
  });

export default instance;
