import {FC, ReactNode} from 'react';
import {Icon, IconName} from '@inperium-corp/icons';

export interface IChildrendWithIconsProps {
  /**
   * Sets loading spinner as the content of the button.
   */
  loading: boolean;

  /**
   * Icon before the children.
   */
  startIcon?: IconName;

  /**
   * Icon after the children.
   */
  endIcon?: IconName;

  /**
   * Children such as labels or icons for the button.
   */
  children: ReactNode;
}

export const ChildrenWithIcons: FC<IChildrendWithIconsProps> = ({loading, startIcon, endIcon, children}) => (
  <div className={loading ? 'position-relative d-flex justify-content-center align-items-center' : ''}>
    {loading && <div className='button-spinner rotate' />}
    <div className={loading ? 'button-opacity-0' : 'd-flex align-items-center'}>
      {startIcon && <Icon icon={startIcon} className='btn-start-icon' />}
      {children}
      {endIcon && <Icon icon={endIcon} className='btn-end-icon' />}
    </div>
  </div>
);
