/* eslint-disable react/no-danger */
import {FC, useCallback} from 'react';
import {renderToString} from 'react-dom/server';
import cn from 'classnames';

interface ISearchHighlightProps {
  /**
   * The color of the highlighted text.
   */
  colorScheme: 'blue' | 'gray' | 'green';

  /**
   * Regexp to match the words to be highlighted.
   */
  matchText: string;

  /**
   * The full text to search for matched words.
   */
  fullText: string;
}

const SearchHighlight: FC<ISearchHighlightProps> = ({colorScheme, matchText, fullText}) => {
  const textHighlight = useCallback(
    (text: string) =>
      renderToString(<span className={cn('search-highlight', `search-highlight--${colorScheme}`)}>{text}</span>),
    [colorScheme]
  );

  const matchTextWords = matchText
    .split(' ')
    .filter((word) => word.length > 0)
    .join('|');
  const highlightedText = fullText.replace(new RegExp(`(${matchTextWords})`, 'gi'), textHighlight('$1'));

  return <span dangerouslySetInnerHTML={{__html: highlightedText}} />;
};

export {SearchHighlight};
