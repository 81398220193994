/**
 * Locks html body from scrolling.
 */
export const lockBodyScrolling = () => {
  const body = document.getElementsByTagName('body')[0];
  body.style.overflowY = 'hidden';
  body.style.paddingRight = '12px';
};

/**
 * Unlocks html body from scrolling.
 */
export const unlockBodyScrolling = () => {
  const body = document.getElementsByTagName('body')[0];
  body.style.overflowY = 'auto';
  body.style.paddingRight = '0';
};
