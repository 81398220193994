import {useInperiumAuth} from '@inperium-corp/auth';
import {getApiUrl, useFetch} from '@inperium-corp/api';
import {IPrincipal} from '../dtos';

export const principalEndpoint = '/users/me';

export function usePrincipal() {
  const {authenticated, initialized} = useInperiumAuth();
  const baseURL = getApiUrl('hub');

  return useFetch<IPrincipal>(
    authenticated && initialized ? {url: principalEndpoint, baseURL} : null,
    // Cache the principal for 5 minutes
    {dedupingInterval: 5 * 60 * 1000}
  );
}
