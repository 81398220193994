/* eslint-disable prettier/prettier */
export default {
  'home': 'Home',
  'account': 'Account',
  'security': 'Security',
  'inperium-sell': 'Inperium Sell',
  'sell': 'Sell',
  'faq': 'FAQ',
  'talk': 'Talk',
  'account-description': 'Activate, manage, and configure user and company accounts in Inperium.',
  'security-description': 'Learn how to enforce security and protect your data in Inperium.',
  'sell-description': 'Bring transparency to your process and speed up your sales cycle.',
  'faq-description': 'Need more assistance? Get instant answers to the most commonly asked questions.',
  'talk-description': 'Connect with your customers and boost team communications with a cloud PBX phone system.'
}