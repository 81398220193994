import cn from 'classnames';
import {FC, useMemo} from 'react';

interface IResponsiveImgProps {
  src: string;
  alt: string;
  height: number;
  width: number;
  sizeMultiplier?: number;
  position?: 'left' | 'center' | 'right';
  className?: string;
}
export const ResponsiveImg: FC<IResponsiveImgProps> = ({
  src,
  alt,
  height,
  width,
  sizeMultiplier = 1,
  position = 'left',
  className = ''
}) => {
  const paddingTop = useMemo(() => (height / (width / sizeMultiplier)) * 100, [height, width, sizeMultiplier]);
  return (
    <div
      className={cn('responsive-img', `responsive-img__position-${position}`, className)}
      style={{
        paddingTop: `${paddingTop}%`
      }}
    >
      <img
        src={src}
        alt={alt}
        style={{
          maxWidth: `${100 * sizeMultiplier}%`
        }}
      />
    </div>
  );
};
