/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {useState, useCallback, ReactNode} from 'react';
import cn from 'classnames';
import {Icon} from '@inperium-corp/icons';
import {Input} from '@inperium-corp/form';

import {lockBodyScrolling, unlockBodyScrolling} from '../../utils';
import {useLunrSearch} from '../../hooks';

interface IGlobalSearchInput<T> {
  /**
   * The placeholder for search input.
   */
  placeholder: string;

  /**
   * Current search language.
   */
  language: string;

  /**
   * The children component for search result presentation.
   */
  children: (result: T & {query: string; handleInputFocus: (isFocused: boolean) => void}) => ReactNode;
}

export function GlobalSearchInput<T>({placeholder, language, children}: IGlobalSearchInput<T>) {
  const [query, setQuery] = useState(``);
  const [isInputFocused, setInputFocused] = useState(false);

  const {results} = useLunrSearch<T>(query, language);

  const handleInputFocus = useCallback((isFocused: boolean, currentQuery = '') => {
    if (isFocused) {
      if (currentQuery.length > 0) {
        setTimeout(() => lockBodyScrolling());
      }
    } else {
      setTimeout(() => unlockBodyScrolling());
    }
    setInputFocused(isFocused);
  }, []);

  const handleInputChange = (value: string) => {
    setQuery(value);
    if (value.length === 0) {
      handleInputFocus(false);
    } else {
      handleInputFocus(true, value);
    }
  };

  return (
    <div className='global-search-input'>
      <Input
        name='search'
        className={cn(
          'global-search-input__input',
          isInputFocused && results.length > 0 && 'global-search-input__input--active'
        )}
        placeholder={placeholder}
        onChange={handleInputChange}
        autoComplete='off'
        size='lg'
        onClick={() => handleInputFocus(true, query)}
      />
      <Icon icon='search' className='global-search-input__search-icon' />
      {isInputFocused && results.length > 0 && (
        <div className='global-search-input__results'>
          {results.slice(0, 10).map((result) => children({...result, query, handleInputFocus}))}
        </div>
      )}
      {isInputFocused && query.length > 0 && (
        <div className='global-search-input__backdrop' onClick={() => handleInputFocus(false)} />
      )}
    </div>
  );
}
