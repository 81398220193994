import {FC} from 'react';
import {Icon} from '@inperium-corp/icons';
import {useInperiumAuth} from '@inperium-corp/auth';

import {AuthStateMenuItem} from './AuthStateMenuItem/AuthStateMenuItem';
import {LanguageLink, MarketingButton} from '..';
import {usePrincipal} from '../../hooks';
import {IIconTextItem} from '../../dtos';

interface INavBarButtonsProps {
  /**
   * All possible set of links to auth state menu.
   */
  links: (IIconTextItem & {type: string})[];

  /**
   * The text to sign in button.
   */
  signInButtonText: string;

  /**
   * The text to sign up button.
   */
  signUpButtonText: string;

  /**
   * The url to sign-in page.
   */
  signInUrl: string;

  /**
   * The url to sign-up page.
   */
  signUpUrl: string;

  /**
   * The condition whether to show or not sign in button.
   * @default true
   */
  showSignInButton?: boolean;

  /**
   * The condition whether to show or not sign up button.
   * @default true
   */
  showSignUpButton?: boolean;
}

export const NavBarButtons: FC<INavBarButtonsProps> = ({
  links,
  signInButtonText,
  signUpButtonText,
  signInUrl,
  signUpUrl,
  showSignInButton = true,
  showSignUpButton = true
}) => {
  const {initialized} = useInperiumAuth();

  const {data: principal, isValidating} = usePrincipal();
  if (!initialized || isValidating) {
    return <></>;
  }

  return (
    <div className='nav-bar-buttons'>
      {principal ? (
        <AuthStateMenuItem principal={principal} links={links} />
      ) : (
        <>
          {showSignInButton && (
            <LanguageLink to={signInUrl} className='sign-in'>
              <Icon icon='user' />
              {signInButtonText}
            </LanguageLink>
          )}
          {showSignUpButton && (
            <MarketingButton
              color='primary'
              variant='outlined'
              size='lg'
              to={signUpUrl}
              className='create-free-account'
            >
              {signUpButtonText}
            </MarketingButton>
          )}
        </>
      )}
    </div>
  );
};
