import {FC, Fragment, useState} from 'react';
import cn from 'classnames';
import {Avatar} from '@inperium-corp/core';
import {useInperiumAuth} from '@inperium-corp/auth';
import {Icon} from '@inperium-corp/icons';

import {LanguageLink, MarketingButton, Text} from '../..';
import {IIconTextItem, IPrincipal} from '../../../dtos';

interface IAuthStateMenuItem {
  /**
   * The authenticated principal object.
   */
  principal: IPrincipal;

  /**
   * All possible set of links which can be displayed for the user.
   */
  links: (IIconTextItem & {type: string})[];
}

export const AuthStateMenuItem: FC<IAuthStateMenuItem> = ({principal, links}) => {
  const {logout} = useInperiumAuth();

  const {firstName, lastName, email, avatarSrc} = principal.user;
  const {products} = principal;
  const fullName = `${firstName} ${lastName}`;

  const [isMobileOpen, setMobileOpen] = useState(false);
  const toggleMobileOpen = () => {
    if (window.innerWidth < 630) {
      setMobileOpen(!isMobileOpen);
    }
  };

  return (
    <div className={cn('auth-state-menu-item', isMobileOpen && 'mobile-open')}>
      <div className='d-flex align-items-center' onClick={toggleMobileOpen}>
        <Avatar src={avatarSrc} name={fullName} size={40} />
        <div className='d-flex flex-column pl-2'>
          <Text tagStyle='h6' className='user-name'>
            {fullName}
          </Text>
          <Text tagStyle='p3' className='user-email'>
            {email}
          </Text>
        </div>
        <Icon icon='caretDown' className='caret' />
      </div>
      <div className='sub-menu-border' />
      <div className='sub-menu-shadow'>
        <div className='sub-menu'>
          {links
            .filter(({type}) => !((type === 'sell' || type === 'talk') && !products.includes(type.toUpperCase())))
            .map(({icon, title, link, type}, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`auth-state-links-${link}-${index}`}>
                  {type === 'logout' ? (
                    <>
                      <div className='hr' />
                      <MarketingButton className={`${type}`} onClick={() => logout()} variant='text'>
                        <img src={icon} alt={title} />
                        <p>{title}</p>
                      </MarketingButton>
                    </>
                  ) : (
                    <LanguageLink to={link!} className={`${type}`}>
                      <img src={icon} alt={title} />
                      <p>{title}</p>
                    </LanguageLink>
                  )}
                </Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};
