import React, {FC, useEffect, useRef} from 'react';
import {WindowLocation} from '@reach/router';
import cn from 'classnames';
import {useClickOutside} from '@inperium-corp/core';
import {LanguageLink, Text, appSettings, NavBarButtons, IIconTextItem, lockBodyScrolling, unlockBodyScrolling} from '@inperium-corp/gatsby-core';

import {useTranslation} from '@/core/i18n/useTranslation';
import {getSidebarItems} from '@/core/data/sidebarItems';
import styles from './SideBar.scss';

interface ISideBarProps {
  location: WindowLocation;
  isMobileMenuOpen: boolean;
  onCloseMobileMenu: () => void;
}
const SideBar: FC<ISideBarProps> = ({location, isMobileMenuOpen, onCloseMobileMenu}) => {
  const {t} = useTranslation('sidebar');
  const {t: tCategories} = useTranslation('categories');
  const {t: tCommon} = useTranslation();
  const links: (IIconTextItem & {type: string})[] = tCommon('nav-bar.auth-state-items');
  const menuItems = getSidebarItems(tCategories);
  const sidebarRef = useRef<HTMLDivElement>(null);
  useClickOutside(sidebarRef, () => {
    if (window.innerWidth > 576) {
      onCloseMobileMenu();
    }
  });

  useEffect(() => {
    if (isMobileMenuOpen) {
      lockBodyScrolling();
    } else {
      unlockBodyScrolling();
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    return unlockBodyScrolling;
  }, []);

  return (
    <div className={cn(styles['sidebar'], isMobileMenuOpen && styles['mobile-open'])} ref={sidebarRef}>
      <div>
        <LanguageLink to='' className={styles['sidebar__logo']}>
          <img src='/icons/logo.svg' alt={t('inperium')} />
          <span className={styles['inperium']}>{t('inperium')}</span>&nbsp;
          <span className={styles['docs']}>{t('docs')}</span>
        </LanguageLink>
        <div className={styles['nav-bar-buttons']}>
          <NavBarButtons
            links={links}
            signInButtonText={tCommon('nav-bar.sign-in')}
            signUpButtonText={tCommon('nav-bar.create-free-account')}
            signInUrl={appSettings.signInUrl}
            signUpUrl={appSettings.signUpUrl}
          />
        </div>
        <div className={styles['sidebar__menu']}>
          {menuItems.map(({icon, link, title}) => (
            <LanguageLink
              key={title}
              to={link}
              className={cn(
                styles['menu-item'],
                link.length > 0 && location.pathname.split('/')[1].includes(link) && styles['menu-item--active']
              )}
            >
              <img src={icon} alt={title} />
              <Text tagStyle='caps3'>{title}</Text>
            </LanguageLink>
          ))}
        </div>
      </div>
      <div className={styles['sidebar__footer']}>
        <LanguageLink colorScheme='blue' to={appSettings.apiDocumentationSiteUrl} className='d-block d-lg-none'>
          <Text tagStyle='p3'>{tCommon('nav-bar.developers-hub')}</Text>
        </LanguageLink>
        <LanguageLink colorScheme='blue' to='https://roadmap.inperium.com' className='d-block d-lg-none'>
          <Text tagStyle='p3'>{tCommon('nav-bar.roadmap')}</Text>
        </LanguageLink>
        <LanguageLink to={appSettings.signInUrl}>
          <Text tagStyle='p3'>{t('dashboard')}</Text>
        </LanguageLink>
        <LanguageLink to={`${appSettings.marketingSiteUrl}`}>
          <Text tagStyle='p3'>{t('inperium-com')}</Text>
        </LanguageLink>
      </div>
    </div>
  );
};

export default SideBar;
