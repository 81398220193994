import {FC} from 'react';
import {Helmet} from 'react-helmet';

interface ISeoHeaderProps {
  title: string;
  description: string;
  canonicalUrl?: string;
  ogFields?: {[key: string]: string};
}

const renderOgField = (fieldName: string, value: string | undefined) => {
  const ogKey = `og:${fieldName}`;
  return value && <meta key={ogKey} property={ogKey} content={value} />;
};

export const SeoHeader: FC<ISeoHeaderProps> = ({title, description, ...props}) => {
  const {ogFields, canonicalUrl} = props;
  let canonicalUrlWithoutTrailingSlash = canonicalUrl;
  if (ogFields) {
    ogFields.url = ogFields.url.replace(/\/$/, '');
    canonicalUrlWithoutTrailingSlash = canonicalUrl ? canonicalUrl.replace(/\/$/, '') : ogFields.url;
  }

  return (
    <Helmet defer={false}>
      <title>{title}</title>
      <meta name='description' content={description} />
      <link rel='canonical' href={canonicalUrlWithoutTrailingSlash} />
      {ogFields && Object.keys(ogFields).map((ogKey) => renderOgField(ogKey, ogFields[ogKey]))}
      <meta name='twitter:card' content='summary_large_image' />
    </Helmet>
  );
};
