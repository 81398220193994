import cn from 'classnames';
import {Link as GatsbyLink} from 'gatsby';
import {FC} from 'react';

import {isUrlAbsolute, isUrlMailTo} from '@inperium-corp/core';

import {useLanguageLink} from '../../hooks/useLanguageLink';
import {Button, IButtonProps} from '../Button';

interface IMarketingButtonProps extends IButtonProps {
  isLink?: boolean;
  customColorScheme?: string;
}

export const MarketingButton: FC<IMarketingButtonProps> = ({
  isLink = false,
  customColorScheme = '',
  color,
  variant,
  size,
  to,
  className,
  ...props
}) => {
  if (customColorScheme && !color) {
    // @ts-ignore TODO: Fix this component. We do not want to allow inherit as a prop.
    color = 'inherit';
  }

  const languageLink = useLanguageLink(to as string);
  const finalLink = to && !isUrlAbsolute(to) && !isUrlMailTo(to) ? languageLink : to;

  return (
    <Button
      {...props}
      className={cn(
        'custom-marketing-button',
        className,
        isLink && 'custom-marketing-button__link',
        customColorScheme && `custom-marketing-button__${customColorScheme}`,
        size && `custom-marketing-button__${size}`
      )}
      color={color}
      variant={variant}
      size={size}
      to={finalLink}
      LinkComponent={GatsbyLink}
    />
  );
};
