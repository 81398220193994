import {TFunction} from 'i18next';
import {getCategories} from './categories';

export const getSidebarItems = (t: TFunction) => {
  return [
    {
      icon: '/icons/sidebar/home.svg',
      link: '',
      title: t('home')
    },
    ...getCategories(t)
  ];
};
