import {IArticleNode} from '../dtos/IArticle';
import {ICategory, CATEGORIES_TYPE} from '../data/categories';

export const sortGroups = (groups: string[]) => {
  return groups.sort((a, b) => a.localeCompare(b));
};

export const getSlug = (article: IArticleNode) => {
  const {order, title} = article.frontmatter;
  return `${order} ${title}`;
};

export const sortArticles = (a: IArticleNode, b: IArticleNode) => {
  if (a.frontmatter.order) {
    const slugA = getSlug(a);
    const slugB = getSlug(b);
    return slugA.localeCompare(slugB);
  }
  return 1;
};

export const groupArticles = (articles: IArticleNode[]) => {
  const articlesByGroup = {};
  articles.forEach((article) => {
    const groupedArticles = articlesByGroup[article.frontmatter.group];
    if (groupedArticles) {
      groupedArticles.push(article);
    } else {
      articlesByGroup[article.frontmatter.group] = [article];
    }
  });
  Object.keys(articlesByGroup).forEach((group) => {
    articlesByGroup[group] = articlesByGroup[group].sort(sortArticles);
  });
  return articlesByGroup;
};

export const getGroupName = (originalName: string) => {
  if (originalName.includes('auto-receptionist')) {
    return 'Auto-Receptionist';
  }
  return originalName.split('-').slice(1).join(' ').replace('and', '&');
};

export const capitalize = (sentence: string) => {
  return sentence
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getGroupUrl = (groupOriginalName: string) => {
  const nameParts = groupOriginalName.split('-');
  return nameParts[0] !== '1' ? groupOriginalName.split('-').slice(1).join('-') : '';
};

export const getArticleLink = ({type, group, url}: {type: string; group: string; url: string}) => {
  const groupUrl = getGroupUrl(group);
  return `${type}${groupUrl ? `/${groupUrl}` : ''}/${url}`;
};

export const getBreadcrumbs = (category: ICategory, group?: string, article?: IArticleNode) => {
  const breadCrumbs =
    category.type !== CATEGORIES_TYPE.SECURITY
      ? [
          {
            text: category.name,
            link: category.link
          }
        ]
      : [];

  if (group) {
    const groupUrl = getGroupUrl(group);
    if (groupUrl) {
      breadCrumbs.push({
        text: getGroupName(group),
        link: `${category.link}/${getGroupUrl(group)}`
      });
    }
  }
  if (article) {
    const {
      frontmatter: {title, type, group, url}
    } = article;
    breadCrumbs.push({
      text: title,
      link: getArticleLink({type, group, url})
    });
  }

  return breadCrumbs;
};
