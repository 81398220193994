import {TFunction} from 'i18next';

export enum CATEGORIES_TYPE {
  ACCOUNT = 'account',
  SECURITY = 'security',
  SELL = 'sell',
  TALK = 'talk',
  FAQ = 'faq'
}

export interface ICategory {
  type: CATEGORIES_TYPE;
  icon: string;
  image: {
    link: string;
    width: number;
    height: number;
  };
  link: string;
  name: string;
  title: string;
  description: string;
}

export const getCategories = (t: TFunction) => {
  return [
    {
      type: CATEGORIES_TYPE.ACCOUNT,
      icon: '/icons/sidebar/account.svg',
      image: {
        link: '/images/home/category-account.png',
        width: 435,
        height: 348
      },
      link: 'account',
      name: t('account'),
      title: t('account'),
      description: t('account-description')
    },
    {
      type: CATEGORIES_TYPE.SECURITY,
      icon: '/icons/sidebar/security.svg',
      image: {
        link: '/images/home/category-security.png',
        width: 435,
        height: 348
      },
      link: 'security',
      name: t('security'),
      title: t('security'),
      description: t('security-description')
    },
    {
      type: CATEGORIES_TYPE.SELL,
      icon: '/icons/sidebar/sell.svg',
      image: {
        link: '/images/home/category-sell.png',
        width: 480,
        height: 340
      },
      link: 'sell',
      name: t('sell'),
      title: t('inperium-sell'),
      description: t('sell-description')
    },
    {
      type: CATEGORIES_TYPE.TALK,
      icon: '/icons/sidebar/talk.svg',
      image: {
        link: '/images/home/category-talk.png',
        width: 480,
        height: 340
      },
      link: 'talk',
      name: t('talk'),
      title: t('Inperium Talk'),
      description: t('talk-description')
    },
    {
      type: CATEGORIES_TYPE.FAQ,
      icon: '/icons/sidebar/faq.svg',
      image: {
        link: '/images/home/category-faq.png',
        width: 480,
        height: 340
      },
      link: 'faq',
      name: t('faq'),
      title: t('faq'),
      description: t('faq-description')
    }
  ];
};
