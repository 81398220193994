/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {FC} from 'react';
import {Icon} from '@inperium-corp/icons';
import {LanguageLink, Text, GlobalSearchInput, SearchHighlight, capitalize} from '@inperium-corp/gatsby-core';
import {useTranslation} from '@/core/i18n/useTranslation';
import {ILunrFields} from '@/core/dtos/ILunrFields';
import {getGroupUrl} from '@/core/utils/articleUtils';

import styles from './GlobalSearchInputWrapper.scss';

export const GlobalSearchInputWrapper: FC = () => {
  const {t, i18n} = useTranslation();

  return (
    <GlobalSearchInput<ILunrFields> placeholder={t('nav-bar.search')} language={i18n.language}>
      {({title, description, type, group, url, query}) => {
        const groupUrl = getGroupUrl(group);
        const articleLink = `${type}${groupUrl ? `/${groupUrl}` : ''}/${url}`;
        return (
          <div key={title} className={styles['global-search-input-wrapper']}>
            <LanguageLink to={articleLink} colorScheme='blue'>
              <Text tagStyle='p2'>
                <SearchHighlight colorScheme='blue' fullText={capitalize(type)} matchText={query} />
              </Text>
              <Icon icon='caretRight' />
              <Text tagStyle='p2'>
                <SearchHighlight colorScheme='blue' fullText={title} matchText={query} />
              </Text>
            </LanguageLink>
            <Text tagStyle='p2' className={styles['description']}>
              <SearchHighlight colorScheme='gray' fullText={description} matchText={query} />
            </Text>
          </div>
        );
      }}
    </GlobalSearchInput>
  );
};
