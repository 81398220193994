import React from 'react';
import Helmet from 'react-helmet';
import cn from 'classnames';
import {withTranslation, WithTranslation, I18nextProvider} from 'react-i18next';
import {WindowLocation} from '@reach/router';
import FontFaceObserver from 'fontfaceobserver';
import smoothscroll from 'smoothscroll-polyfill';
import {ThemeProvider} from 'emotion-theming';
import {lightTheme} from '@inperium-corp/core';
import {appSettings} from '@inperium-corp/gatsby-core';
import {InperiumApiProvider} from '@inperium-corp/api';
import {IInperiumAuthConfig, InperiumAuthProvider} from '@inperium-corp/auth';
import {InperiumTrackingWrapper} from '@inperium-corp/tracking';

import i18n from '@/core/i18n/i18n';
import {capitalize, getGroupUrl} from '@/core/utils/articleUtils';

import ProjectSeoHeader from '../ProjectSeoHeader/ProjectSeoHeader';
import FallBackComponent from '../FallBackComponent/FallBackComponent';
import SideBar from '../SideBar/SideBar';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

import styles from './Layout.scss';

interface IProps extends WithTranslation {
  lang: string;
  location: WindowLocation;
  url: string;
  children: React.ReactNode;
  canonicalUrl?: string;
  seoKey?: string;
  productGroup?: string;
}

const authConfig: IInperiumAuthConfig = {
  afterLoginRedirectUri: `${process.env.GATSBY_ACCOUNT_URL}/start`,
  afterLogoutRedirectUri: appSettings.documentationSiteUrl,
  loginUri: `${process.env.GATSBY_ACCOUNT_URL}/login`,
  loginTimingOutSeconds: 240 * 60,
  logoutCountdownSeconds: 2 * 60,
  hubUrl: process.env.GATSBY_ACCOUNT_URL,
  baseApiUrl: process.env.GATSBY_API_HUB_URL
};
const entityConfigs = {users: {url: '/users'}};
class Layout extends React.Component<IProps, {isMainFontReady: boolean; isMobileMenuOpen: boolean}> {
  constructor(props: IProps) {
    super(props);
    i18n.changeLanguage(props.lang);

    this.state = {
      isMainFontReady: false,
      isMobileMenuOpen: false
    };

    const fontWeights = [700, 600, 500, 400];
    const loadWaitingPromises = fontWeights.map((weight) => new FontFaceObserver('Inter', {weight}).load());
    loadWaitingPromises.push(new FontFaceObserver('Muller', {weight: 500}).load());

    Promise.all(loadWaitingPromises).then(
      () => {
        this.setState({isMainFontReady: true});
      },
      () => {
        this.setState({isMainFontReady: true});
      }
    );
  }

  componentDidMount() {
    smoothscroll.polyfill();

    const matcher = window.matchMedia('(prefers-color-scheme: dark)');
    function onUpdate() {
      if (matcher.matches) {
        document.head.insertAdjacentHTML('beforeend', "<link rel='icon' href='/favicon-dark.ico' id='favicon-dark' />");
      } else {
        document.head.insertAdjacentHTML('beforeend', "<link rel='icon' href='/favicon.ico' id='favicon-light' />");
      }
    }
    matcher.addListener(onUpdate);
    onUpdate();
  }

  toggleMobileMenu = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({isMobileMenuOpen: !this.state.isMobileMenuOpen});
  };

  closeMobileMenu = () => {
    this.setState({isMobileMenuOpen: false});
  };

  openMobileMenu = () => {
    this.setState({isMobileMenuOpen: true});
  };

  render() {
    const {isMainFontReady, isMobileMenuOpen} = this.state;
    const {t, children, location, seoKey, url, canonicalUrl, productGroup} = this.props;
    return (
      <InperiumTrackingWrapper>
        <InperiumAuthProvider {...authConfig}>
          <InperiumApiProvider defaultApi='hub' entityConfigs={entityConfigs}>
            <I18nextProvider i18n={i18n}>
              <ThemeProvider theme={lightTheme}>
                {seoKey && (
                  <ProjectSeoHeader
                    title={
                      productGroup
                        ? capitalize(t(`${seoKey}${productGroup ? `-${getGroupUrl(productGroup)}` : ``}.title`))
                        : t(`${seoKey}.title`)
                    }
                    description={t(`${seoKey}${productGroup ? `-${getGroupUrl(productGroup)}` : ``}.description`)}
                    ogUrl={url}
                    canonicalUrl={canonicalUrl || url}
                  />
                )}
                <Helmet>
                  <script async src='/intercom-chat.js' />
                </Helmet>
                {!isMainFontReady && <FallBackComponent />}
                <div className={styles['page']}>
                  <SideBar
                    location={location}
                    isMobileMenuOpen={isMobileMenuOpen}
                    onCloseMobileMenu={this.closeMobileMenu}
                  />
                  <div className={cn(styles['page__content'], isMobileMenuOpen && styles['mobile-menu-opened'])}>
                    <NavBar isMobileMenuOpen={isMobileMenuOpen} onToggleMobileMenu={this.toggleMobileMenu} />
                    {children}
                    <Footer />
                  </div>
                </div>
              </ThemeProvider>
            </I18nextProvider>
          </InperiumApiProvider>
        </InperiumAuthProvider>
      </InperiumTrackingWrapper>
    );
  }
}

export default withTranslation('seo')(Layout);
