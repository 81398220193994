/* eslint-disable react/no-array-index-key */
import React, {FC} from 'react';
import {Container, Row, Col} from '@inperium-corp/core';
import {Icon, IconName} from '@inperium-corp/icons';
import {LanguageLink, Text, appSettings} from '@inperium-corp/gatsby-core';

import {useTranslation} from '@/core/i18n/useTranslation';
import styles from './Footer.scss';

const Footer: FC = () => {
  const {t, i18n} = useTranslation();
  const columns: [{title: string; link: string}][] = t('footer.columns');
  const socialMedia: {[key: string]: string}[] = t('social-media');
  return (
    <section className={styles['footer']}>
      <Container>
        <Row>
          {columns.map((column, index) => (
            <Col key={`footer-${index}`} xs={12} md={2} className='p-0 d-flex flex-column'>
              {column.map((item) => (
                <LanguageLink
                  key={item.title}
                  to={`${appSettings.marketingSiteUrl}/${item.link}`}
                  className={styles['footer__link']}
                >
                  <Text tagStyle='p3'>{item.title}</Text>
                </LanguageLink>
              ))}
            </Col>
          ))}
          <Col xs={12} md={3} className='p-0'>
            <Text tagStyle='caps3' className={styles['follow-us']}>
              {t('footer.follow-us')}
            </Text>
            <div className={styles['footer__social-media']}>
              {Object.keys(socialMedia).map((name) => (
                <a key={name} href={socialMedia[name]}>
                  <Icon icon={name as IconName} />
                </a>
              ))}
            </div>
          </Col>
          <Col xs={12} md={3} className='p-0'>
            <Text tagStyle='p3' className={styles['copyright']}>
              {t('footer.copyright')}
            </Text>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
