/* eslint-disable prettier/prettier */
export default {
  'home-page': {
    'header': {
      'title': 'DASdasdasdasdasdasd',
      'subtitle': 'asdasdasdasd grow in a customer-centric world.',
      'get-started': 'Get Started for Free',
      'watch-demo': 'Watch Demo',
      'watch-demo-sm': 'Demo'
    },
    'products-section': {
      'header': {
        'title': 'Get the Products You Need — One, Some or All',
        'description': 'All of our solutions integrate seamlessly with your existing ecosystem, so you can choose exactly the ones you need. Our technology adapts to fit your business needs and scales easily to support your growth.'
      }
    },
    'first-class-section': {
      'title': 'Count on Success with Our First-Class Support',
      'description': 'We are committed to helping you achieve your goals. Our customer success team will ensure you are efficiently onboarded, fully trained and comfortable using our solutions to their fullest.'
    },
    'feedbacks-section': {
      'title': "Don't Take Our Word for It",
      'feedbacks': [
        {
          'name': 'Lisa Newcar',
          'position': 'CTO @ Newcompany LLC.',
          'title': 'Awesome CRM system',
          'text': "Inperium speeds response to new customer inquiries by 200%. The best thing about it is that you don't have to switch between different ecosystems — you get a true all-in-one experience using Inperium!"
        },
        {
          'name': 'Lisa Oldcar',
          'position': 'CTO @ Newcompany LLC.',
          'title': '',
          'text': 'Inperium speeds response to new customer inquiries by 200%.'
        }
      ]
    }
  }
}