/**
 * Escapes special lunr chars in the word to not break the search
 * @param word
 * @returns string
 */
export const escapeSpecialChars = (word: string) => {
  const specialChars = ['*', '^', ':', '~', '\\', '+', '-'];
  return word
    .split('')
    .map((letter) => (specialChars.includes(letter) ? `\${letter}` : letter))
    .join('');
};
