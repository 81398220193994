import common from './common';
import sidebar from './sidebar';
import home from './home';
import categories from './categories';
import seo from './seo';

export const en = {
  common,
  sidebar,
  home,
  categories,
  seo
};
