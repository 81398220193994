/* eslint-disable no-underscore-dangle */
import {useState, useEffect} from 'react';
import {makeAllWordsRequired} from '../utils';

declare global {
  interface Window {
    __LUNR__: {
      [lang: string]: {
        index: any;
        store: any;
      };
    };
  }
}

/**
 * Returns matched results from lunr index
 * @param query search query
 * @param lang current search language
 */
export function useLunrSearch<IResult>(query: string, lang: string) {
  const [results, setResults] = useState([] as IResult[]);
  useEffect(() => {
    if (!query || !window.__LUNR__) {
      setResults([]);
      return;
    }
    const lunrIndex = window.__LUNR__[lang];
    if (!lunrIndex) {
      setResults([]);
      return;
    }
    const requiredQuery = makeAllWordsRequired(query);
    const searchResults: {ref: string; score: number}[] = lunrIndex.index.search(requiredQuery);
    setResults(
      searchResults
        // We put some fields in the index, while they should not participate in search.
        // To exclude that fileds from search we filter them by low score value
        .filter(({score}) => score > 10)
        .map(({ref}) => {
          return lunrIndex.store[ref];
        })
    );
  }, [query, lang]);

  return {results};
}
