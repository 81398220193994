/* eslint-disable prettier/prettier */
export default {
  'title': 'Inperium Documentation',
  'subtitle': 'Everything you need to get started within minutes and use Inperium products to their fullest.',
  'view': 'View',
  'documentation': 'Documentation',
  'products-section': {
    'title': 'Products',
    'subtitle': 'Explore Inperium products and get quick answers to your questions.',
    'products-list': [
      {
        'icon': '/icons/home/sell.svg',
        'link': 'sell',
        'name': 'Sell',
        'description': 'Bring transparency to your process and speed up your sales cycle.',
      },
      {
        'icon': '/icons/home/talk.svg',
        'link': 'talk',
        'name': 'Talk',
        'description': 'Connect with your customers and boost team communications with a cloud PBX phone system.',
      }
    ],
    'view-all': 'View all'
  },
  'api-section': {
    'title': 'Looking for API documentation?',
    'link-text': 'Visit Inperium developers hub'
  }
}