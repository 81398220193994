import React, {useEffect, useState} from 'react';
import {Spinner} from '@inperium-corp/core';

import styles from './FallBackComponent.scss';

interface IProps {
  initialLoading?: boolean;
}
const FallBackComponent = ({initialLoading = false}: IProps) => {
  const [showSpinner, setShowSpinner] = useState(initialLoading);

  useEffect(() => {
    const timeout = setTimeout(() => setShowSpinner(true), 500);
    const {body} = document;
    body.setAttribute('style', 'overflow: hidden;');
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      body.removeAttribute('style');
      body.classList.add(styles['content-hidden']);
      setTimeout(() => {
        if (body.classList.contains(styles['content-hidden'])) {
          body.classList.add(styles['content-visible']);
        }
      });
    };
  }, [initialLoading]);

  return <div className={styles['fallback-component-wrapper']}>{showSpinner && <Spinner isLoading />}</div>;
};

export default FallBackComponent;
