/* eslint-disable prettier/prettier */
import {appSettings} from "@inperium-corp/gatsby-core";

export default {
  'nav-bar': {
    'search': 'Search documentation',
    'developers-hub': 'Developers Hub',
    'support': 'Support',
    'roadmap': 'Roadmap',
    'sign-in': 'Sign In',
    'create-free-account': 'Create Free Account',
    'auth-state-items': [
      {
        "icon": "/icons/common/account.svg",
        "title": "Account",
        "link": appSettings.accountUrl,
        "type": "account"
      },
      {
        "icon": "/icons/common/sell.svg",
        "title": "INPERIUM SELL",
        "link": appSettings.sellProductUrl,
        "type": "sell"
      },
      {
        "icon": "/icons/common/talk.svg",
        "title": "INPERIUM TALK",
        "link": appSettings.talkProductUrl,
        "type": "talk"
      },
      {
        "icon": "/icons/common/logout.svg",
        "title": "Logout",
        "type": "logout"
      }
    ]
  },
  'social-media': {
    'linkedin': 'https://www.linkedin.com/company/53479259/',
    'twitter': 'https://twitter.com/inperiumcorp',
    'facebook': 'https://www.facebook.com/Inperium-109572674115682/'
  },
  'article-sidebar-title': 'On this page',
  'article-next-steps': 'Next steps',
  'footer': {
    'columns': [
      [
        {
          'title': 'Contact Us',
          'link': 'contact-us'
        },
        {
          'title': 'Provide Feedback',
          'link': 'contact-us'
        }
      ],
      [
        {
          'title': 'Terms of Service',
          'link': 'terms-of-service'
        },
        {
          'title': 'Privacy Policy',
          'link': 'privacy-policy'
        }
      ]
    ],
    'follow-us': 'Follow us on social media',
    'copyright': `Copyright © ${new Date().getFullYear()} Inperium Corp.`
  }
};