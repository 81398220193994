import React, {FC} from 'react';
import {Container, Row, Col} from '@inperium-corp/core';
import {Icon} from '@inperium-corp/icons';
import {LanguageLink, Text, appSettings, NavBarButtons, IIconTextItem} from '@inperium-corp/gatsby-core';

import {useTranslation} from '@/core/i18n/useTranslation';
import {GlobalSearchInputWrapper} from '../GlobalSearchInputWrapper/GlobalSearchInputWrapper';

import styles from './NavBar.scss';

interface INavBarProps {
  isMobileMenuOpen: boolean;
  onToggleMobileMenu: () => void;
}

const NavBar: FC<INavBarProps> = ({isMobileMenuOpen, onToggleMobileMenu}) => {
  const {t} = useTranslation();
  const links: (IIconTextItem & {type: string})[] = t('nav-bar.auth-state-items');

  return (
    <div className={styles['nav-bar']}>
      <Container className='pl-0 pr-0'>
        <Row>
          <Col xs={2} sm={1} className='d-flex d-lg-none align-items-center '>
            <button id='burger-button' type='button' className={styles['mobile-burger']} onClick={onToggleMobileMenu}>
              <Icon icon={isMobileMenuOpen ? 'cross' : 'menu'} />
            </button>
          </Col>
          <Col xs={10} sm={5} lg={6}>
            <GlobalSearchInputWrapper />
          </Col>
          <Col xs={6} className={styles['nav-bar__links']}>
            <LanguageLink colorScheme='blue' to={appSettings.apiDocumentationSiteUrl}>
              <Text tagStyle='p2'>{t('nav-bar.developers-hub')}</Text>
            </LanguageLink>
            <LanguageLink colorScheme='blue' to='https://roadmap.inperium.com'>
              <Text tagStyle='p2'>{t('nav-bar.roadmap')}</Text>
            </LanguageLink>
            <NavBarButtons
              links={links}
              signInButtonText={t('nav-bar.sign-in')}
              signUpButtonText={t('nav-bar.create-free-account')}
              signInUrl={appSettings.signInUrl}
              signUpUrl={appSettings.signUpUrl}
            />
          </Col>
          <Col xs={5} className='d-none d-sm-flex d-lg-none'>
            <NavBarButtons
              links={links}
              signInButtonText={t('nav-bar.sign-in')}
              signUpButtonText={t('nav-bar.create-free-account')}
              signInUrl={appSettings.signInUrl}
              signUpUrl={appSettings.signUpUrl}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NavBar;
