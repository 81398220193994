import {ReactNode, createRef, FunctionComponent, ElementType, MouseEvent} from 'react';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {IconName} from '@inperium-corp/icons';
import {isUrlAbsolute, isUrlMailTo, IDefaultProps} from '@inperium-corp/core';

import {ChildrenWithIcons} from './ChildrenWithIcons';

export interface IButtonProps extends IDefaultProps {
  /**
   * The color of the button.
   * @default primary
   */
  color?: 'primary' | 'secondary' | 'tertiary';

  /**
   * The color scheme of the button.
   * @default contained
   */
  variant?: 'text' | 'outlined' | 'contained';

  /**
   * Size of the button.
   * @default 'md'
   */
  size?: 'sm' | 'md' | 'lg' | 'xl';

  /**
   * Spans the full width of the Button parent.
   * @default false
   */
  fullWidth?: boolean;

  /**
   * Sets loading spinner as the content of the button.
   * @default false
   */
  loading?: boolean;

  /**
   * Disables the Button, preventing mouse events,
   * even if the underlying component is an `<a>` element.
   * @default false
   */
  disabled?: boolean;

  /**
   * Providing a `to` will render an `<Link>` element, _styled_ as a button.
   */
  to?: string;

  /**
   * Defines HTML button type attribute.
   * @default 'button'
   */
  type?: 'button' | 'reset' | 'submit';

  /**
   * By default, the Button will use a regular HTML 'a' tag for absolute
   * links and react-router for relative links if the 'to' prop is supplied.
   * However, you can override the react-router link component to use a custom one.
   * @default 'Link'
   */
  LinkComponent?: ElementType;

  /**
   * Normally <Button> components will render a HTML <button> element.
   * However you can render whatever you'd like, adding a href prop will
   * automatically render an <a /> element. You can use the as prop to
   * render whatever your heart desires.
   */
  as?: string | ElementType;

  /**
   * On click event handler for the button.
   */
  onClick?: (event: MouseEvent<any>) => void;

  /**
   * On mouse down event handler for the button.
   */
  onMouseDown?: (event: MouseEvent<any>) => void;

  /**
   * Children such as labels or icons for the button.
   */
  children?: ReactNode;

  /**
   * Icon before the children.
   */
  startIcon?: IconName;

  /**
   * Icon after the children.
   */
  endIcon?: IconName;

  /**
   * Manually set the buttons active state.
   */
  active?: boolean;
}

/**
 * The main button component. Used for popups, forms, etc.
 */
export const Button: FunctionComponent<IButtonProps> = ({
  color = 'primary',
  variant = 'contained',
  size = 'sm',
  className,
  type = 'button',
  as,
  fullWidth = false,
  active = false,
  loading = false,
  disabled = false,
  to,
  children,
  startIcon,
  endIcon,
  LinkComponent = Link,
  ...props
}) => {
  const buttonRef = createRef<HTMLButtonElement | HTMLAnchorElement>();

  const classes = cn(
    className,
    'btn',
    `btn-${size}`,
    `btn-${color}-${variant}`,
    fullWidth && 'btn-full-width',
    disabled && 'btn-disabled',
    active && 'active'
  );

  if (to && !disabled) {
    const isAbsoluteLink = to && (isUrlAbsolute(to) || isUrlMailTo(to));
    return isAbsoluteLink ? (
      <a href={to.toString()} className={classes}>
        <ChildrenWithIcons loading={loading} startIcon={startIcon} endIcon={endIcon}>
          {children}
        </ChildrenWithIcons>
      </a>
    ) : (
      <LinkComponent to={to} className={classes}>
        <ChildrenWithIcons loading={loading} startIcon={startIcon} endIcon={endIcon}>
          {children}
        </ChildrenWithIcons>
      </LinkComponent>
    );
  }

  const Component = as || 'button';
  return (
    <Component {...props} disabled={disabled || loading} className={classes} ref={buttonRef} type={type}>
      <ChildrenWithIcons loading={loading} startIcon={startIcon} endIcon={endIcon}>
        {children}
      </ChildrenWithIcons>
    </Component>
  );
};
