import React from 'react';
import {SeoHeader, appSettings} from '@inperium-corp/gatsby-core';

interface IProps {
  title: string;
  description: string;
  ogUrl?: string;
  canonicalUrl?: string;
  ogImage?: string;
}

const ProjectSeoHeader: React.FunctionComponent<IProps> = ({
  title,
  description,
  ogUrl = '',
  canonicalUrl = '',
  ogImage
}) => {
  const absoluteOgUrl = `${appSettings.documentationSiteUrl}${ogUrl}`
  const absoluteCanonicalUrl = canonicalUrl ? `${appSettings.documentationSiteUrl}${canonicalUrl}` : absoluteOgUrl;
  return (
    <SeoHeader
      title={title}
      description={description}
      canonicalUrl={absoluteCanonicalUrl}
      ogFields={{
        url: absoluteOgUrl,
        title,
        description,
        image: ogImage || `${absoluteOgUrl}/images/home/header.png`,
        'image:width': '960',
        'image:height': '480'
      }}
    />
  );
};

export default ProjectSeoHeader;
