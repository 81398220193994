/* eslint-disable react/no-danger */
import {FC, ReactNode} from 'react';

import {cx} from '@inperium-corp/css-in-js';

import {TextTags} from './types/TextTags';

export interface ITextProps {
  /**
   * The style to use for the Text component.
   */
  tagStyle: TextTags;

  /**
   * The HTML tag to use for the Text component.
   * @default the same as @style prop.
   */
  tag?: TextTags;

  /**
   * An additional class name that can be passed
   * in case custom styles are needed.
   */
  className?: string;

  /**
   * Value, that need to be set directly as html
   */
  dangerouslySetInnerHTML?: string;

  /**
   * The children of the row.
   */
  children: ReactNode;
}

export const Text: FC<ITextProps> = ({tagStyle, tag, className, dangerouslySetInnerHTML, children}) => {
  let Tag = 'span';

  if (['p', 'p-md', 'p2', 'p3'].includes(tagStyle)) {
    Tag = 'p';
  }
  if (tagStyle.includes('h')) {
    Tag = tagStyle;
  }

  if (tag) {
    Tag = tag;
  }

  return (
    <>
      {dangerouslySetInnerHTML ? (
        <Tag
          // @ts-ignore
          className={cx(`text text-${tagStyle}`, className)}
          dangerouslySetInnerHTML={{__html: dangerouslySetInnerHTML}}
        />
      ) : (
        // @ts-ignore
        <Tag className={cx(`text text-${tagStyle}`, className)}>{children}</Tag>
      )}
    </>
  );
};
