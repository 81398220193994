import {useTranslation} from 'react-i18next';

const DEFAULT_LANG = 'en';

/**
 * Returns url path with language prefix, if language is not default.
 * @param to url to process
 */
export const useLanguageLink = (to: string) => {
  const {
    i18n: {language}
  } = useTranslation();

  return !language || language === DEFAULT_LANG ? `/${to}` : `/${language}/${to}`;
};
